import React, { useMemo } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import GlobalLayout from "../layouts/globalLayout";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import PictureCardWithBorder from "../components/PictureCardWithBorder/pictureCardWithBorder";
import Carousel from "../components/Carousel/carousel";
import * as styles from "./index.module.css";
import useClientServerWorkaround from "../components/helperHooks";
import ContactUsForm from "../components/ContactUsForm/contactUsForm";
import PictureCard from "../components/PictureCard/pictureCard";
import EmailSubscribeForm from "../components/EmailSubscribeForm/emailSubscribeForm";

const imagesQuery = graphql`{
  dinnerTablePic: file(relativePath: {eq: "homePage/dinnerTable.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  headerImage: file(relativePath: {eq: "homePage/HeaderImage.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  rentalsImage: file(relativePath: {eq: "homePage/Rentals.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  servicesImage: file(relativePath: {eq: "homePage/Services.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  shopImage: file(relativePath: {eq: "homePage/Shop.jpeg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  flowerIcon: file(relativePath: {eq: "icons/flowerIcon.png"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  squiggleIcon: file(relativePath: {eq: "icons/long_squiggle-01.png"}) {
    childImageSharp {
      gatsbyImageData
    }
  },
  caseyJulie: file(relativePath: {eq: "homePage/MeetJulieandCasey.jpeg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  shop1: file(relativePath: {eq: "homePage/theLoveShackShop1.jpeg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  shop2: file(relativePath: {eq: "homePage/theLoveShackShop2.jpeg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  allShopifyProduct(limit: 8) {
    edges {
      node {
        images {
          gatsbyImageData(height: 200)
        }
        handle
      }
    }
  }
}
`;

export default function Home() {
  const homePageQuery = useStaticQuery(imagesQuery);
  const { flowerIcon, squiggleIcon, headerImage, rentalsImage, servicesImage, shopImage, caseyJulie, shop1, shop2, allShopifyProduct } = homePageQuery;
  const largeScreen = useMediaQuery("(min-width:600px)");
  const key = useClientServerWorkaround();

  return (
    <GlobalLayout key={key}>
      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeaderSubContainer} style={{ backgroundColor: 'rgba(243, 200, 121, 0.3)' }}>
          <div className={styles.headerImageWrapper}>
            <GatsbyImage
              image={headerImage.childImageSharp.gatsbyImageData}
              style={{
                margin: 'auto',
                height: '100%'
              }}
            />
          </div>
        </div>
        <div className={styles.subHeaderSubContainer}
          style={{
            backgroundColor: 'rgba(153, 38, 29, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div className={styles.vintageHeaderText}>vintage with a touch of magic</div>
          <Link to="/about/" className={styles.vintageButtonLink}>
            <Button className={styles.vintageButton} variant="contained">Learn More</Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          marginTop: "90px",
          marginBottom: "50px",
          flexWrap: "wrap",
        }}
      >
        <PictureCard onClick={() => { navigate('/rentals') }} fluid={rentalsImage.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'cover' }} text="rentals" textOnBottom />
        <PictureCard onClick={() => { navigate('/services') }} fluid={servicesImage.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'cover' }} text="services" textOnBottom />
        <PictureCard onClick={() => { navigate('/shop') }} fluid={shopImage.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'cover' }} text="shop" textOnBottom />
      </div>
      <div
        className={styles.meetCaseyJulie}
        style={{
          backgroundColor: 'rgba(153, 38, 29, 0.3)'
        }}>
        <div className={styles.meetCaseyJuliePic}>
          <div style={{ width: '400px', height: '265px', position: 'relative' }}>
            <GatsbyImage
              image={flowerIcon.childImageSharp.gatsbyImageData}
              style={{
                position: 'relative',
                height: '250px',
                width: '250px',
                zIndex: 1
              }}
            />
            <GatsbyImage
              image={caseyJulie.childImageSharp.gatsbyImageData}
              style={{
                position: 'absolute',
                height: '250px',
                width: '250px',
                zIndex: 2,
                border: '6px solid white',
                top: 0,
                left: '125px'
              }}
            />
          </div>
        </div>
        <div className={styles.meetCaseyJulieDescription}>
          <div className={styles.meetCaseyJulieDescriptionTitle}>
            MEET CASEY AND JULIE
          </div>
          <div className={styles.meetCaseyJulieDescriptionDesc}>
            Welcome to the Love Shack! We are so happy you are here. We are the mother daughter duo, Julie & Casey, both equally obsessed with vintage items. If you are looking for a wonderfully curated collection of vintage items that will bring a touch of magic to your special events and everyday moments then you came to the right place! Turning old to new is our favorite thing to do. Thank you for being our kindred spirit and bringing life and light back into vintage.
          </div>
          <Link to="/about/" className={styles.vintageButtonLink}>
            <Button className={styles.vintageButton} variant="contained">READ OUR STORY</Button>
          </Link>
        </div>
      </div>
      <div className={styles.rentalFavs}>
        <div className={styles.rentalFavsTitle}>
          some of our favorite items
        </div>
        <Link to="/rentals/" className={styles.rentalFavsLink}>
          BUILD A WISHLIST FOR YOUR EVENT
        </Link>
        <div className={styles.carousel}>
          <Carousel
            pictures={allShopifyProduct.edges.map(edge => edge.node.images[0].gatsbyImageData)}
            pictureChildren={[]}
            links={allShopifyProduct.edges.map(edge => `/shop/${edge.node.handle}`)}
            disableDots
          />
        </div>
      </div>
      <div
        className={styles.loveShackShop}
        style={{
          backgroundColor: 'rgba(153, 38, 29, 0.3)'
        }}>
        <div className={styles.loveShackShopPic}>
          <div className={styles.loveShackPicContainer}>
            <GatsbyImage
              image={shop1.childImageSharp.gatsbyImageData}
              style={{
                position: 'relative',
                height: '350px',
                width: '250px',
                zIndex: 1
              }}
            />
            <GatsbyImage
              image={shop2.childImageSharp.gatsbyImageData}
              className={styles.loveShackPicTwo}
              style={{
                position: 'absolute',
                height: '175px',
                width: '175px',
                zIndex: 2,
                border: '6px solid white',
                top: '80px',
                left: '230px'
              }}
            />
          </div>
        </div>
        <div className={styles.loveShackShopDesc}>
          <div className={styles.loveShackShopDescTitle}>
            THE LOVE SHACK SHOP
          </div>
          <div className={styles.loveShackShopDescDesc}>
            Vintage inspired items that add a little magic to your
            everyday life
          </div>
          <Link to="/shop/products" className={styles.vintageButtonLink}>
            <Button className={styles.vintageButton} variant="contained">BROWSE ALL ITEMS</Button>
          </Link>
        </div>
      </div>
      <div style={{ height: '50px', backgroundColor: 'white' }} />
      <div className={styles.emailSubscribeContainer}>
        <div className={styles.emailSubscribeHeader}>add a little magic to your inbox</div>
        <EmailSubscribeForm />
        <GatsbyImage image={squiggleIcon.childImageSharp.gatsbyImageData} className={styles.subscribeFormSquiggle} />
      </div>
      {/* <div className={styles.instagramImgs}>
        <GatsbyImage
            image={squiggleIcon.childImageSharp.gatsbyImageData} 
            style={{
              width: '100%'
            }}
          />  
        <div className={styles.instaTitle}>
          <a href="https://www.instagram.com/loveshackvintage.ca/">FOLLOW ALONG @LOVESHACKVINTAGE.CA</a>
        </div>
      </div> */}
    </GlobalLayout>
  );
}
