// extracted by mini-css-extract-plugin
export var embla = "carousel-module--embla--1GegM";
export var embla__viewport = "carousel-module--embla__viewport--2AMye";
export var isDraggable = "carousel-module--is-draggable--2wVSZ";
export var isDragging = "carousel-module--is-dragging--20ff9";
export var embla__container = "carousel-module--embla__container--3gHtd";
export var embla__slide = "carousel-module--embla__slide--1p99c";
export var embla__slide__inner = "carousel-module--embla__slide__inner--1fRio";
export var embla__slide__content__container = "carousel-module--embla__slide__content__container--LD_nT";
export var fadein = "carousel-module--fadein--3aXJo";
export var embla__slide__img = "carousel-module--embla__slide__img--KsMDm";
export var embla__button = "carousel-module--embla__button--1Rtgn";
export var embla__button__svg = "carousel-module--embla__button__svg--TBglN";
export var embla__button__prev = "carousel-module--embla__button__prev--2pPGb";
export var embla__button__next = "carousel-module--embla__button__next--4qW_r";
export var embla__dots = "carousel-module--embla__dots--33NoK";
export var embla__dot = "carousel-module--embla__dot--2UBcW";
export var embla__dot__active = "carousel-module--embla__dot__active--3iQY2";