import React, { useEffect, useState, useCallback, useRef } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrevButton, NextButton, DotButton } from "./carouselButtons";
import * as styles from "./carousel.module.css";
import { Link } from "gatsby";

const AUTOPLAY_INTERVAL = 12000;

export const useRecursiveTimeout = (callback, delay) => {
  const [isRunning, setIsRunning] = useState(false);
  const stop = useCallback(() => setIsRunning(false), [setIsRunning]);
  const play = useCallback(() => setIsRunning(true), [setIsRunning]);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isRunning) return;
    let id = 0;

    const tick = () => {
      if (!isRunning) return clearTimeout(id);
      savedCallback.current();
      requestAnimationFrame(() => (id = setTimeout(tick, delay)));
    };
    requestAnimationFrame(() => (id = setTimeout(tick, delay)));

    return () => {
      if (id) clearTimeout(id);
      stop();
    };
  }, [isRunning, delay, stop]);

  return { play, stop };
};

export default function Carousel(props) {
  const { pictures, pictureChildren, enableAutoPlay, disableDots, links } = props;
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    containScroll: "trimSnaps"
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);
  const scrollTo = useCallback(
    (index) => {
      if (!embla) return;
      embla.scrollTo(index);
      stop();
    },
    [embla, stop],
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
    embla.on("pointerDown", stop);
  }, [embla, onSelect, stop]);

  useEffect(() => {
    if (enableAutoPlay) {
      play();
    }
  }, [play, enableAutoPlay]);

  return (
    <div className={styles.embla}>
      <div className={styles.embla__viewport} ref={viewportRef}>
        <div className={styles.embla__container}>
          {pictures.map((fluidProps, index) => (
            <div className={styles.embla__slide} key={fluidProps.originalName}>
              <div className={styles.embla__slide__inner}>
                {links && (
                  <Link to={links[index]}>
                    <GatsbyImage
                      image={fluidProps}
                      style={{ overflow: "visible" }}
                      imgStyle={{ objectFit: "contain" }} />
                  </Link>
                )}
                {!links && (
                  <GatsbyImage
                    image={fluidProps}
                    style={{ overflow: "visible" }}
                    imgStyle={{ objectFit: "contain" }} />
                )}
                {selectedIndex === index && (
                  <div className={styles.embla__slide__content__container}>
                    <div>{!!pictureChildren[index] && pictureChildren[index]}</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      {!disableDots && (<div className={styles.embla__dots}>
        {scrollSnaps.map((_, index) => (
          <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
        ))}
      </div>)}
    </div>
  );
}
