import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import { navigate } from "@reach/router";
import * as styles from "./pictureCardWithBorder.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    border: "5px solid #F3C879",
    textDecoration: 'none',
    boxShadow: 'none'
  },
  text: {
    display: "flex",
    width: "100%",
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#8f8f8f",
    fontSize: 18,
    fontFamily: "'Bergen', sans-serif",
  },
  media: {
    width: "100%",
    display: "flex",
  },
  actionArea: {
    
  },
});

export default function PictureCardWithBorder(props) {
  const { image, image2, text, buttonText, buttonDisabled, buttonTextDisabled, href, subText, buttonClick } = props;
  const classes = useStyles();
  const [hovering, setIsHovering] = useState(false);

  return (
    <Card 
      className={clsx(classes.root, styles.card)} 
      raised={hovering} 
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}>
      <CardActionArea
        className={clsx(classes.actionArea, { [classes.hover]: hovering })}
        
        href={href}
      >
        <CardMedia className={classes.media}>
          <div style={{ width: "100%" }}>
            {image && <GatsbyImage
              image={image}
              alt={text}
              style={{
                overflow: "visible",
                width: "100%",
                margin: "auto",
                position: 'relative',
                zIndex: 2,
                opacity: image2 ? undefined : 1
              }}
              className={styles.topImage}
              imgStyle={{ objectFit: "contain" }} />}
            {image2 && <GatsbyImage
              image={image2}
              alt={text}
              style={{
                overflow: "visible",
                width: "100%",
                margin: "auto",
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1
              }}
              className={styles.bottomImage}
              imgStyle={{ objectFit: "contain" }} />}
          </div>
        </CardMedia>        
      </CardActionArea>
      <CardContent>
        {!!text && <div className={clsx(classes.text, styles.text)}>{text}</div>}
        {!!subText && subText.map(sub => (<div key={sub} className={styles.subText}>{sub}</div>))}
      </CardContent>
      <CardActions style={{ justifyContent: "center", textDecoration: 'none' }}>
          <button
            style={{
              
            }}
            className={clsx(styles.actionButton, { 
              [styles.actionButtonDisabled]: buttonDisabled,
              [styles.actionButtonActive]: !buttonDisabled
            })}
            onClick={buttonClick}
          >
            {buttonDisabled ? buttonTextDisabled : buttonText}
          </button>
        </CardActions>
    </Card>
  );
}
