import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import * as styles from "./pictureCard.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: 300,
    height: 300,
    overflow: 'visible',
    marginBottom: 80
  },
  text: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    zIndex: 3,
    fontSize: 24,
    color: "white",
  },
  media: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  actionArea: {
    width: "100%",
    height: "100%",
    zIndex: 5
  },
});

export default function PictureCard(props) {
  const { fluid, duoToneFluid, text, textStyles, cardStyles, onClick, textOnBottom, imgStyle } = props;
  const classes = useStyles();
  const [hovering, setIsHovering] = useState(false);

  return (
    <Card className={classes.root} raised={hovering} style={cardStyles}>
      <CardActionArea
        className={clsx(classes.actionArea, { [classes.hover]: hovering })}
        onMouseOver={(event) => {
          setIsHovering(true);
        }}
        onMouseLeave={(event) => {
          setIsHovering(false);
        }}
        onClick={onClick}
      >
        {!!text && !textOnBottom && <div className={clsx(classes.text, styles.text)} style={textStyles}>{text}</div>}
        <CardMedia className={classes.media}>
          <GatsbyImage
            image={fluid}
            style={{
              overflow: "visible",
              display: !hovering || !duoToneFluid ? "block" : "none",
              width: "100%",
              height: "100%",
            }}
            imgStyle={imgStyle ? imgStyle : { objectFit: "fill" }} />
          {!!duoToneFluid && (
            <GatsbyImage
              image={duoToneFluid}
              style={{ overflow: "visible", display: hovering ? "block" : "none", width: "100%", height: "100%" }}
              imgStyle={imgStyle ? imgStyle : { objectFit: "fill" }} />
          )}
        </CardMedia>
        
        {!!text && textOnBottom && (
          <CardContent>
            <div className={styles.textOnBottom} style={textStyles}>{text}</div>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
}
